// extracted by mini-css-extract-plugin
export var DescriptionMobile = "programme-2025-module--DescriptionMobile--cJw0k";
export var TitleMobile = "programme-2025-module--TitleMobile--B8OaA";
export var baseStyles = "programme-2025-module--baseStyles--JD6Pf";
export var container = "programme-2025-module--container--5j5DX";
export var containerDescription = "programme-2025-module--containerDescription--ix+nv";
export var containerImage = "programme-2025-module--containerImage--Edb6p";
export var containerSubDescription = "programme-2025-module--containerSubDescription--bAVod";
export var containerTitle = "programme-2025-module--containerTitle--1JybS";
export var dietaryDescription = "programme-2025-module--dietaryDescription--vEPbd";
export var dietaryExplanation = "programme-2025-module--dietaryExplanation---I3+Q";
export var dietaryExplanationMobile = "programme-2025-module--dietaryExplanationMobile--WCuUD";
export var dietaryTitle = "programme-2025-module--dietaryTitle--ri7Qp";
export var highlightedText = "programme-2025-module--highlightedText--7vFrt";
export var imageMobile = "programme-2025-module--imageMobile--SLEqY";
export var middleContainer = "programme-2025-module--middleContainer--ZUJ2W";
export var middleDescription = "programme-2025-module--middleDescription--J-zVL";
export var middleTitle = "programme-2025-module--middleTitle--pG-iS";
export var programmeContainer = "programme-2025-module--programmeContainer--Z-R8h";
export var supplementsContainer = "programme-2025-module--supplementsContainer--iuxPw";
export var textContainer = "programme-2025-module--textContainer--zGwll";
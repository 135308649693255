import React, { useEffect } from 'react';
import * as styles from './modules/helpProgram.module.scss';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HelpProgram = ({ data }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{data.title}</h2>
      <p className={styles.description}>{data.description}</p>
      <div className={styles.programExplanation}>
        {data.items.map((item, index) => (
          <>
            <div className={styles.programItem}>
              <div className={styles.image}>
                <img src={item.image.publicURL} alt={item.alt} />
              </div>
              <h3 className={styles.programTitle}>{item.title}</h3>
              <p className={styles.programDescription}>{item.description}</p>
            </div>
            {index !== 2 && <span className={styles.plus}>+</span>}
          </>
        ))}
      </div>
    </section>
  );
};

export default HelpProgram;

import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as styles from '../modules/programme-2025.module.scss';
import CartContext from '../context/CartContext';
import { navigate } from 'gatsby';
import Link from '../components/utils/link';
import DietaryCart from '../components/repeatable/dietaryCart';
import ButtonSeeSomething from '../components/repeatable/buttonSeeSomething';
import HelpProgram from '../components/repeatable/helpProgram';
import HeaderPage from '../components/repeatable/headerPage';
import Reassurance from '../components/static/rea';

const Programme2025Bemum = ({ location }) => {
  useContext(CartContext);

  const {
    pageProgramme2025Yaml: {
      title,
      description,
      subDescription,
      seo,
      choices,
      see_testimonies,
      working,
      explanation,
      star,
      star_empty,
    },
  } = useStaticQuery(graphql`
    query pageProgramme2025Query {
      pageProgramme2025Yaml {
        title
        description
        subDescription
        star {
          publicURL
        }
        star_empty {
          publicURL
        }
        choices {
          id
          image {
            publicURL
          }
          title
          description
          cart
          program_type
        }

        seo {
          title
          meta
          canonical
        }

        see_testimonies {
          title
        }

        working {
          title
          description
          items {
            image {
              publicURL
            }
            alt
            title
            description
          }
        }

        explanation {
          items {
            id
            title
            description
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={seo.title} description={seo.meta} canonical={seo.canonical} />
      <div className={styles.baseStyles}>
        <HeaderPage title={title} description={description} subDescription={subDescription} />

        <section className={styles.programmeContainer}>
          <div className={styles.supplementsContainer}>
            {choices.map((choice) => (
              <DietaryCart choice={choice} star={star}  starEmpty={star_empty}/>
            ))}
          </div>
          <ButtonSeeSomething text={see_testimonies.title} />
        </section>

        <HelpProgram data={working} />

        {/* desktop */}
        {explanation.items.map((section, index) => (
          <section key={section.key} className={styles.dietaryExplanation}>
            {index % 2 === 0 ? (
              <>
                <div className={`image-${index}`}>
                  <GatsbyImage image={section.image.childImageSharp.gatsbyImageData} alt={section.alt} />
                </div>
                <div className={styles.textContainer}>
                  <h2 className={styles.dietaryTitle}>{section.title}</h2>
                  <p className={styles.dietaryDescription} dangerouslySetInnerHTML={{ __html: section.description }} />
                </div>
              </>
            ) : (
              <>
                <div className={styles.middleContainer}>
                  <h2 className={styles.middleTitle}>{section.title}</h2>
                  <p className={styles.middleDescription} dangerouslySetInnerHTML={{ __html: section.description }} />
                </div>
                <div className={`image-${index}`}>
                  <GatsbyImage image={section.image.childImageSharp.gatsbyImageData} alt={section.alt} />
                </div>
              </>
            )}
          </section>
        ))}

        {/* mobile */}
        {explanation.items.map((section, index) => (
          <section key={section.key} className={styles.dietaryExplanationMobile}>
            <>
              {/* <div className={`imageMobile-${index}`}> */}
              <div className={styles.imageMobile}>
                <GatsbyImage image={section.image.childImageSharp.gatsbyImageData} alt={section.alt} />
              </div>
              <h2 className={styles.TitleMobile}>{section.title}</h2>
              <p className={styles.DescriptionMobile} dangerouslySetInnerHTML={{ __html: section.description }} />
            </>
          </section>
        ))}

        <Reassurance/>
      </div>
    </Layout>
  );
};

export default Programme2025Bemum;
